import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Col, Row } from 'antd';

const { Meta } = Card;
/**
 *  <Row id="card-grid" gutter={gutter} justify="center" wrap="true">
       </Col>
        </Row>
<Col flex={flexVal} xs={xsCol} sm={smCol} md={mdCol} lg={lgCol} xl={xlCol} xxl={xxlCol} >
 */
class NFTCards extends React.Component {
  render() {
    const xsCol = { span: 24, offset: 0 };
    const smCol = { span: 24, offset: 0 };
    const mdCol = { span: 12, offset: 0 };
    const lgCol = { span: 12, offset: 0 };
    const xlCol = { span: 10, offset: 0 };
    const xxlCol = { span: 10, offset: 0 };
    const gutter = [{ xs: 8, sm: 16, md: 24 }, { xs: 8, sm: 16, md: 24 }];
    const flexVal = "auto";

    return (
      <div className="page-wrapper">
        
        <Card 
           className="card-therapy-info"  >
            <div className="Odd">
            <Meta
            title={
              <div className="card-headers">        
               <span className="anchor" id="TeleHealth"></span>
                  <FormattedMessage id="header7" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card7" defaultMessage="Telesalud"
            values={{
              p: chunks => <p>{chunks}</p>
            }} />
            </div>
            <div style={{marginTop: "10px", paddingBottom: "10px"}}className="Even">
          <Meta
            title={
              <div  className="card-headers">
                 <span className="anchor" id="Individual Therapy"></span>
                  <FormattedMessage id="header1" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card1" defaultMessage="Individual Therapy"
            values={{
              p: chunks => <p>{chunks}</p>
            }} />
            </div>
          <div  className="Odd">
          <Meta
            title={
              <div  className="card-headers">
                 <span className="anchor" id="Therapy for Children"></span>
                  <FormattedMessage id="header2" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card2" defaultMessage="Therapy for Children"
            values={{
              p: chunks => <p>{chunks}</p>
            }}
          />
          </div>
          <div style={{marginTop: "10px", paddingBottom: "10px"}} className="Even">
          <Meta
            title={
              <div  className="card-headers">
                 <span className="anchor" id="Animal Assisted Therapy"></span>
                  <FormattedMessage id="header3" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card3" defaultMessage="Animal Assisted Therapy"
            values={{
              p: chunks => <p>{chunks}</p>
            }} />
            </div>
            <div className="Odd">
          <Meta
            title={
              <div  className="card-headers">
                 <span className="anchor" id="Community-based Therapy"></span>
                  <FormattedMessage id="header4" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card4" defaultMessage="Community-based Therapy"
            values={{
              p: chunks => <p>{chunks}</p>
            }} />
            </div>
            <div style={{marginTop: "10px", paddingBottom: "10px"}} className="Even">
          <Meta
            title={
              <div  className="card-headers">
             <span className="anchor" id="Family Therapy"></span>
                  <FormattedMessage id="header5" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card5" defaultMessage="Family Therapy"
            values={{
              p: chunks => <p>{chunks}</p>
            }} />
            </div>
            <div className="Odd">
          <Meta
            title={
              <div  className="card-headers">
                 <span className="anchor" id="Group Therapy"></span>
                  <FormattedMessage id="header6" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card6" defaultMessage="Couples Therapy"
            values={{
              p: chunks => <p>{chunks}</p>
            }} />
            </div>
            <div style={{marginTop: "10px", paddingBottom: "10px"}} className="Even">
          <Meta
            title={
              <div  className="card-headers">
                 <span className="anchor" id="Couples Therapy"></span>
                  <FormattedMessage id="header9" defaultMessage=" " />
              </div>
            }
          />
          <FormattedMessage id="card8" defaultMessage="Couples Therapy"
            values={{
              p: chunks => <p>{chunks}</p>
            }} />
            </div>
        </Card>
      </div>
    );
  }
};

export default NFTCards;
import React from 'react';
import NFTCards from '../NFTCards';
import { FormattedMessage } from 'react-intl';

class Service extends React.Component {

    render() {
        return (
            
                <div className="page-wrapper">
                  <div className="service-bg">
          <div className="service-bg-content">
            <h1 style={{ fontSize: "40px", paddingTop: "5px" }} ><FormattedMessage id="btn-service" defaultMessage="" /></h1>
            <h1 style={{ fontSize: "20px", paddingTop: "10px" }} ><FormattedMessage id="service1" defaultMessage="" /></h1>
          </div>
        </div> 
        <div style={{height: "30px", backgroundColor: "white", paddingTop: "10px" }} >
        <h1>

        </h1>
        </div>
                <NFTCards />
            </div>
        );
    }
}

export default Service;
import React from 'react';
import { FormattedMessage, } from 'react-intl';
//import 'antd/dist/antd.css';
import { Form, Input, Button, Select, Radio, DatePicker } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import 'antd/dist/antd.css';
import axios from 'axios'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactGA from "react-ga4";

const { Option } = Select;

const TEST_SITE_KEY = "6LcczCAeAAAAALYo0SOn3D30vgZjnifPOMruytnx";

ReactGA.initialize("G-2BP5LMKE0W");


ReactGA.event({
  category: "your category",
  action: "your action",
  label: "your label", // optional
  value: 99, // optional, must be a number
  nonInteraction: true, // optional, true/false
  transport: "xhr", // optional, beacon/xhr/image
});

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      name: "",
      subject: "",
      phone: "",
      option: "",
      response: "",
      day: "",
    };
    this.state = { isButtonDisabled: false };
    this.state = { isVerified: false };
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleSelectedDate = this.handleSelectedDate.bind(this);

  }

  handleCaptchaResponseChange(event) {
    console.log(this);
    const name = 'response';
    this.setState({
      [name]: event,
      isVerified: true
    });
    //console.log(event);
  }
  handleInputChange(event, data) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  /**
   * Called when user selects a new value for subject input in the form
   * @param {*} event value entered by user
   */
  handleSubjectChange(event) {
    ReactGA.event({
      category: "Form-Change",
      action: "Subject-Change",

    });
    console.log(this);
    const name = 'subject';
    this.setState({
      [name]: event
    });

  }
  /**
  * Called when user selects a new value for subject input in the form
  * @param {*} event value entered by user
  */
  handleOptionChange(event) {
    console.log(this);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = 'option';
    this.setState({
      [name]: value
    });
  }

  handleSelectedDate(date, dateString) {
    console.log("this is the date " + dateString);
    console.log(this);
    const name = 'day';
    this.setState({
      [name]: dateString
    });
  }
  /*handleSelectedDate(date, dateString) {
    console.log(this);
    this.setState({
      [startDate]: date
    });
  }

*/
  /**
   * Called when user submits email form and calls the /api/email API to submit their inquiry
   * @param {*} e 
   */
  submitRequest = async (e) => {
    ReactGA.event({
      category: "Form-Submit",
      action: "SubmitButton",

    });
    this.setState({
      isVerified: false
    });
    console.log('test');
    console.log(this.state);
    ReactGA.send({ hitType: "pageview", page: "/Failure", title: "Test Title" });
    axios({
      method: "POST",
      url: "/api/email",
      data: {
        option: this.state.option,
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        subject: this.state.subject,
        phone: this.state.phone,
        response: this.state.response,
        day: this.state.day,
      }
    })
      .then((response) => {
        console.log('send email response:');
        console.log(response);
        if (response.data.message.startsWith('Success')) {
          this.props.history.push("/Success");
          //alert("Email sent, awesome!"); 

        } else if (response.data.message.startsWith('Fail')) {
          this.props.history.push("/Failure");
          //alert("Oops, something went wrong. Try again")

        }
      })
      .catch(err => {
        console.log(err);
        this.props.history.push("/Failure");
      })
  }

  render() {

    return (
      <Form id='Form-Submit' name="inquiry" layout="vertical" onFinish={this.submitRequest} >
        <h2 style={{ paddingTop: 20 + 'px' }} ><FormattedMessage id='Ct-h2' defaultMessage="Submit Inquiry" /></h2>
        <Form.Item
          name={['user', 'name']}
          label={<FormattedMessage id='Ct-Name' defaultMessage="Name" />}
          rules={[
            {
              required: true,
              message: <FormattedMessage id='Validation-name' defaultMessage="" />,

            },
          ]}
        >
          <Input
            onChange={this.OnChange}
            name="name"
            value={this.state.name}
          />
        </Form.Item>
        <Form.Item
          name=" "
          label={<FormattedMessage id='Ct-Radio' defaultMessage="Service is for" />}
          rules={[
            {
              required: true,
              message: <FormattedMessage id='RadioRequirement' defaultMessage="Please choose one!" />,
            },
          ]}
        >
          <Radio.Group
            name="option"
            value={this.state.option}
            onChange={this.handleOptionChange}>
            <Radio value=" for me"><FormattedMessage id='Radio-a' defaultMessage="Myself" /></Radio>
            <Radio value=" for someone else"><FormattedMessage id='Radio-b' defaultMessage="Somebody Else" /></Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item

          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            name="dateOfBirth"
            label={<FormattedMessage id='Ct-dateOfBirth' defaultMessage="Date of Birth" />}
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
            }}
          >
            <DatePicker
              initialValues={moment('2015-01-01', 'YYYY-MM-DD')}
              onChange={this.handleSelectedDate}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={<FormattedMessage id='Ct-Phone Number' defaultMessage="Phone Number" />}

            rules={[
              {
                required: true,
              },
            ]}
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              margin: '0 8px',
            }}
          >
            <Input
              onChange={this.handleInputChange}
              name="phone"
              value={this.state.phone}
              addonBefore={PrefixSelector}
            />
          </Form.Item>

        </Form.Item>
        <Form.Item required
          name={['user', 'email']}
          rules={[
            {
              type: 'email',
              message: <FormattedMessage id='Validation-email' defaultMessage="" />,
            },
            {
              required: true,
              message: <FormattedMessage id='Validation-email2' defaultMessage="" />,
            },
          ]}
          label={<FormattedMessage id='Ct-Email' defaultMessage="Email" />}
        >
          <Input
            onChange={this.handleInputChange}
            name="email"
            value={this.state.email}
          />
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id='Ct-Select' defaultMessage="Select" />}
        >
          <Select
            onChange={this.handleSubjectChange}
            name="subject"
            value={this.state.subject}
          >
            <Select.Option value="Family Therapy">Family Therapy</Select.Option>
            <Select.Option value="Individual Therapy">Individual Therapy</Select.Option>
            <Select.Option value="Therapy for Children and Teens">Therapy for Children and Teens</Select.Option>
            <Select.Option value="Animal Assisted Therapy">Animal Assisted Therapy</Select.Option>
            <Select.Option value="Community-based Therapy ">Community-based Therapy </Select.Option>
            <Select.Option value="Group Therapy">Group Therapy</Select.Option>
            <Select.Option value="Couple Therapy">Couple Therapy</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="message"
          required rules={[
            {
              type: "string",
              required: true,
              message: <FormattedMessage id='Validation-intro' defaultMessage="" />,
              min: 10,
            },
          ]}
          label={<FormattedMessage id='Ct-Introduction' defaultMessage="Introduction" />}
        >
          <Input.TextArea
            onChange={(event) => this.handleInputChange(event)}
            name="message"
            rows={6} />

        </Form.Item>
        <div><FormattedMessage id='Submit-warning' defaultMessage="" values={{
          p: chunks => <p>{chunks}</p>
        }} /></div>
        <Form.Item >
          <ReCAPTCHA
            ref={(el) => { this.recaptcha = el; }}
            sitekey={TEST_SITE_KEY}
            onChange={this.handleCaptchaResponseChange}
          />
          <Button id='Contact-Submit' disabled={!this.state.isVerified} type="primary" htmlType="submit" style={{ marginLeft: "81%", background: "#4cc2f5" }}>
            <FormattedMessage id='Submit' defaultMessage="" />
          </Button>
        </Form.Item>
      </Form>
    );
  };
}


const PrefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 70, }}>
      <Option value="1">+1</Option>
      <Option value="52">+52</Option>
    </Select>
  </Form.Item>
);

export default withRouter(ContactForm);
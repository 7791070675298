import React from 'react';
import NFTCarousel from '../NFTCarousel';
import Carousel1 from '../Carousel';
import 'antd/dist/antd.css';
import { Card } from 'antd';
import LandingPageCards from '../LandingPageCards'
// <NFTCarousel />
const { Meta } = Card;

class Home extends React.Component {

    render() {
        return (
            <div>
                <NFTCarousel />
                <LandingPageCards />
            </div>
        );
    }
}

export default Home;
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Button, Image } from 'antd';
import NFTVideo from '../NFTVideo';
import { HashLink } from 'react-router-hash-link';
import ContactForm from '../Form';
import Location from '../Location';

const { Meta } = Card;
/**

    <FormattedMessage id="Vision" defaultMessage="" />
 *  */
class LandingPageCards extends React.Component {
  constructor(props) {
    super(props);

    this.updateWidthAndHeight = () => {

      //var mode = window.innerWidth > 1150 ? 'horizontal' : 'vertical';
      this.setState(state => ({
        width: window.innerWidth,
      }));

    };
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      width: window.innerWidth,

    };

  }
  //className="ant-btn-appoinment"
  //className='testingCalc'
  componentDidMount() {
    window.addEventListener("resize", this.updateWidthAndHeight);
  }
  componentDidUpdate() {
    window.addEventListener("resize", this.updateWidthAndHeight);
  }
  render() {

    if (this.state.width < 1065) {
      return (
        <div className="page-wrapper">

          <Card
            className="card-therapy-info" id="LP-card" >
            <div className="LP-BG-1">
              <div className="LP-BG-content" style={{ textAlign: 'center' }}>
                <i className="ISize"> <FormattedMessage id="Vision-1" defaultMessage=" "
                  values={{
                    p: chunks => <p>{chunks}</p>
                  }} /></i>
                <Meta
                  title={
                    <div className="card-headers3">
                      <FormattedMessage id="EMDR-2" defaultMessage="EMDR " />
                    </div>
                  }
                />
                <FormattedMessage id="EMDR-3" defaultMessage="EMDR " />
                <Button shape="round" type="primary" className="ant-btn-appoinment"><HashLink to="/Contact" ><FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></HashLink></Button>


                <div className="LP-BG-9" style={{ textAlign: 'center' }}>
                  <div className="LP-BG-content">
                    <Meta
                      title={
                        <div style={{ paddingBottom: '4vmax' }} className="card-headers1">
                          <FormattedMessage id="LP-H-8" defaultMessage=" " />
                        </div>
                      }
                    />
                    <div>
                      <div style={{ paddingBottom: '2vmax' }}>
                        <FormattedMessage id="LP-Card-8" defaultMessage="Couples Therapy"
                          values={{
                            p: chunks => <p className='test'>{chunks}</p>
                          }} />
                      </div>
                      <p style={{ textAlign: 'center', color: 'black' }}><a href="https://neatfamilytherapy.com/Contact" target="blank">< FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></a></p>

                      <Card bordered={false} className='Footer-info2'
                      >
                        <div style={{ fontSize: 'large', textAlign: 'center' }} >
                          <ul className='custom-bullet phone2' >
                            <li>
                              <a href="tel:+16693079030" className='footer-link'>(669) 307-9030</a>
                            </li>
                          </ul>
                        </div>
                        <div style={{ fontSize: 'large', textAlign: 'center' }}>
                          <ul className='custom-bullet email2'>
                            <li> <a className='footer-link' href="mailto:ihurtado@neatfamilytherapy.com?subject=Therapy Inquiry​ ">ihurtado@neatfamilytherapy.com​ </a></li>
                          </ul>
                        </div>
                        <div style={{ fontSize: 'large', textAlign: 'center' }}>
                          <ul className='custom-bullet location2'>
                            <li>
                              <a className='footer-link' href="https://www.google.com/maps/place/830+Stewart+Dr+125+a,+Sunnyvale,+CA+94085/@37.3834881,-122.009618,17z/data=!4m5!3m4!1s0x808fb63c214a675f:0xbd5bef7ba20e3f71!8m2!3d37.3834881!4d-122.009618">830 Stewart Drive, suite 125 A, Sunnyvale, CA 94085</a>
                            </li>
                          </ul>
                        </div>

                        <Location />

                      </Card>
                    </div>
                  </div>
                </div>
                <div className="contact-form-wrapper-home-small" >

                  <div style={{ textAlign: 'left' }}>
                    <ContactForm />
                  </div>
                </div>




              </div>
            </div>

            <div className="LP-BG-2">
              <div className="LP-BG-content">
                <Meta
                  title={
                    <div className="card-headers2">
                      <FormattedMessage id="LP-H-1" defaultMessage=" " />
                    </div>
                  }
                />
                <FormattedMessage id="LP-Card-1" defaultMessage="Telesalud"
                  values={{
                    p: chunks => <p>{chunks}</p>
                  }} />
                <p><a href="https://www.emdria.org/about-emdr-therapy/" target="blank">< FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></a></p>

              </div>
            </div>
            <div className="img-border-big">
              <div className='img-block'>
              </div>
              <Image
                preview={false}

                src='./images/emdr.jpg'
              />


            </div>



            <div className="LP-BG-3">
              <div className="LP-BG-content">
                <Meta
                  title={
                    <div className="card-headers2">
                      <FormattedMessage id="LP-H-2" defaultMessage="Working Immigrant" />
                    </div>
                  }
                />
                <FormattedMessage id="LP-Card-2" defaultMessage=" "
                  values={{
                    p: chunks => <p>{chunks}</p>
                  }} />

              </div>
            </div>
            <div className="img-border-big">

              <Image
                preview={false}

                src="./images/wwi-img.jpg"
              />

            </div>

            <div style={{ fontSize: '3vmin' }} >
              <b> <FormattedMessage id="LP-Card-H-1" defaultMessage=" "
                values={{
                  p: chunks => <p>{chunks}</p>
                }} /></b>
                </div>
              <FormattedMessage id="LP-Card-H-2" defaultMessage=" "
                values={{
                  p: chunks => <p>{chunks}</p>
                }} />
            

            <div className="LP-BG-8">
              <div className="LP-BG-content">
                <Meta
                  title={
                    <div className="card-headers1">
                      <FormattedMessage id="LP-H-7" defaultMessage=" " />
                    </div>
                  }
                />
                <FormattedMessage id="LP-Card-7" defaultMessage="Family Therapy"
                  values={{
                    p: chunks => <p>{chunks}</p>
                  }} />
                <p><a href="https://neatfamilytherapy.com/AboutUs" target="blank">< FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></a></p>
              </div>
            </div>
            <div>
              <NFTVideo />
              <Button shape="round" type="primary" className="ant-btn-appoinment"><HashLink to="/Contact" ><FormattedMessage id="Make an Appointment" defaultMessage="Meet Our Clinician" /></HashLink></Button>
            </div>



          </Card>
        </div>
      );
    }
    /**
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     */
    //style={{ width: "90vmin", height: "60vmin" }}
    return (
      <div className="page-wrapper">

        <Card
          className="card-therapy-info" id="LP-card" >
          <div className="LP-BG-1">
            <div className="LP-BG-content" style={{ textAlign: 'center' }}>
              <i className="ISize"> <FormattedMessage id="Vision-1" defaultMessage=" "
                values={{
                  p: chunks => <p>{chunks}</p>
                }} /></i>
              <Meta
                title={
                  <div className="card-headers1">
                    <FormattedMessage id="EMDR-2" defaultMessage="EMDR " />
                  </div>

                }
              />
              <FormattedMessage id="EMDR-3" defaultMessage="EMDR " />
              <Button shape="round" type="primary" className="ant-btn-appoinment"><HashLink to="/Contact" ><FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></HashLink></Button>

              <div className='rowC'>
                <div className="LP-BG-9" style={{ textAlign: 'center' }}>
                  <div className="LP-BG-content">
                    <Meta
                      title={
                        <div style={{ paddingBottom: '5vmax' }} className="card-headers1">
                          <FormattedMessage id="LP-H-8" defaultMessage=" " />
                        </div>
                      }
                    />
                    <div>
                      <div style={{ paddingBottom: '3vmax' }}>
                        <FormattedMessage id="LP-Card-8" defaultMessage="Couples Therapy"
                          values={{
                            p: chunks => <p className='test'>{chunks}</p>
                          }} />
                      </div>
                      <p style={{ textAlign: 'center', color: 'black' }}><a href="https://neatfamilytherapy.com/Contact" target="blank">< FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></a></p>

                      <Card bordered={false} className='Footer-info'
                      >

                        <div style={{ fontSize: 'large', textAlign: 'center' }} >
                          <ul className="custom-bullet phone" >
                            <li>
                              <a href="tel:+16693079030" className='footer-link'>(669) 307-9030</a>
                            </li>
                          </ul>
                        </div>

                        <div style={{ fontSize: 'large', textAlign: 'center' }}>
                          <ul className="custom-bullet email">
                            <li> <a className='footer-link' href="mailto:ihurtado@neatfamilytherapy.com?subject=Therapy Inquiry​ ">ihurtado@neatfamilytherapy.com​ </a></li>

                          </ul>
                        </div>

                        <div style={{ fontSize: 'large', textAlign: 'center' }}>
                          <ul className="custom-bullet location">
                            <li>
                              <a className='footer-link' href="https://www.google.com/maps/place/830+Stewart+Dr+125+a,+Sunnyvale,+CA+94085/@37.3834881,-122.009618,17z/data=!4m5!3m4!1s0x808fb63c214a675f:0xbd5bef7ba20e3f71!8m2!3d37.3834881!4d-122.009618">830 Stewart Drive, suite 125 A, Sunnyvale, CA 94085</a>
                            </li>
                          </ul>
                        </div>
                        <div style={{ paddingTop: '3vmax' }}>
                          <Location />
                        </div>
                      </Card>



                    </div>

                  </div>
                </div>
                <div className="contact-form-wrapper-home" >

                  <div style={{ textAlign: 'left' }}>
                    <ContactForm />
                  </div>
                </div>
              </div>



            </div>
          </div>
          <div className='rowC'>
            <div className="LP-BG-2">
              <div className="LP-BG-content">
                <Meta
                  title={
                    <div className="card-headers2">
                      <FormattedMessage id="LP-H-1" defaultMessage=" " />
                    </div>
                  }
                />
                <FormattedMessage id="LP-Card-1" defaultMessage="Telesalud"
                  values={{
                    p: chunks => <p>{chunks}</p>
                  }} />
                <p><a href="https://www.emdria.org/about-emdr-therapy/" target="blank">< FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></a></p>

              </div>
            </div>
            <div className="img-border">

              <Image
                preview={false}

                src='./images/emdr.jpg'
              />
              <div className='img-block'>
              </div>

            </div>
          </div>
          <div className='rowC'>
            <div className="img-border">

              <Image
                preview={false}

                src="./images/wwi-img.jpg"
              />
              <div className='img-block'>
              </div>
            </div>
            <div className="LP-BG-3">
              <div className="LP-BG-content">
                <Meta
                  title={
                    <div className="card-headers2">
                      <FormattedMessage id="LP-H-2" defaultMessage="Working Immigrant" />
                    </div>
                  }
                />
                <FormattedMessage id="LP-Card-2" defaultMessage=" "
                  values={{
                    p: chunks => <p>{chunks}</p>
                  }} />

              </div>
            </div>
          </div>
          <div style={{ fontSize: '3vmin' }} >
            <b><FormattedMessage id="LP-Card-H-1" defaultMessage=" "
              values={{
                p: chunks => <p>{chunks}</p>
              }} /></b>
            <FormattedMessage id="LP-Card-H-2" defaultMessage=" "
              values={{
                p: chunks => <p>{chunks}</p>
              }} />
          </div>
          <div className='rowC'>
            <div className="LP-BG-8">
              <div className="LP-BG-content">
                <Meta
                  title={
                    <div className="card-headers1">
                      <FormattedMessage id="LP-H-7" defaultMessage=" " />
                    </div>
                  }
                />
                <FormattedMessage id="LP-Card-7" defaultMessage="Family Therapy"
                  values={{
                    p: chunks => <p>{chunks}</p>
                  }} />
                <p><a href="https://neatfamilytherapy.com/AboutUs" target="blank">< FormattedMessage id="LP-card-LM" defaultMessage="Learn More" /></a></p>
              </div>
            </div>
            <div>

              <table>
                <tr>
                  <td>
                    <div style={{ width: "90vmin", height: "60vmin" }}>
                      <NFTVideo />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button shape="round" type="primary" className="ant-btn-appoinment"><HashLink to="/Contact" ><FormattedMessage id="Make an Appointment" defaultMessage="Meet Our Clinician" /></HashLink></Button>
                  </td>
                </tr>
              </table>

            </div>
          </div>


        </Card>
      </div>
    );
  }
};
/**
 * 
 * 
 */
export default LandingPageCards;
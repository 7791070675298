import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '300px',
  //paddingLeft: '40%',
  height: '300px'
};

const onLoad = marker => {
    console.log('marker: ', marker)
  }
const position = {
    lat: 37.3585896,
    lng: -122.0281796
};

class GoogleMaps extends Component {
  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyBpHulmmRFBVMdIcLX0_xfOniMkq-Cv2Zw"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={10}
        >
            <Marker
                onLoad={onLoad}
                position={position}
                />
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
      </LoadScript>
    )
  }
}
export default GoogleMaps;
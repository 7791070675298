import React from 'react';
import { FormattedMessage, } from 'react-intl';
//import 'antd/dist/antd.css';
import 'antd/dist/antd.css';
import { withRouter } from 'react-router-dom';
import ContactForm from '../Form'

/**
 * 
 * 
 *  <h2 style={{ paddingTop: 20 + 'px' }} ><FormattedMessage id='Ct-h2' defaultMessage="Submit Inquiry" /></h2>
            <h3><FormattedMessage id='Ct-h3' defaultMessage="Please enter your inquiry details below:" /></h3>        
 * 
 * 
 */

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  
  render() {
    return (
      <div className="page-wrapper" >
        <div className="contact-bg">
          <div className="contact-bg-content">
            <h1 style={{ fontSize: "40px" }} ><FormattedMessage id="Contact" defaultMessage="" /></h1>
            <h1 style={{ fontSize: "20px", paddingTop: "5px" }} ><FormattedMessage id="Contact1" defaultMessage="" /></h1>
          </div>
        </div>
        <div className="ant-card ant-card-bordered" >
          <div className="contact-form-wrapper" >
           <ContactForm/>
            
          </div>
        </div>
      </div>
    );
  };
}


export default withRouter(Contact);
import React from 'react';
import GoogleMaps from '../GoogleMaps';
import 'antd/dist/antd.css';
import { PhoneOutlined } from '@ant-design/icons';

/**
 * <div className="LocationText" icon={<PhoneOutlined />}> <PhoneOutlined /> <a href="tel:+16693079030">Call (669) 307-9030</a></div>
 * <div className="LocationText"><a href="https://www.google.com/maps/place/500+E+Remington+Dr+%2328,+Sunnyvale,+CA+94087/@37.3585896,-122.0281796,17z/data=!3m1!4b1!4m6!3m5!1s0x808fb679c7216e11:0xaae28eb269cc0f01!8m2!3d37.3585896!4d-122.0281796!16s%2Fg%2F11n09cwpps"><p>500 E Remington Dr, suite 28, Sunnyvale CA 94087</p></a></div>
                
 * 
 */

class Location extends React.Component {

    render() {
        return (
            <div >
                <div className="GoogleMap"><GoogleMaps /></div>
            </div>
        );
    }
}

export default Location;
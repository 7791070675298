import React from "react";
import { Image } from "antd";
import { Box, Text } from "@chakra-ui/react";

export default function Logo(props) {
    return (
        <Box {...props}>
            <Image
                style={{ height: '100%', width: '100%' }}
                src="./images/NeatFamilyTherapyLogo_v2.png"
                //src="./images/nftlogo.png"
                className="logo"
                alt="Neat Family Therapy Network, Inc."
                preview={false}
            />
        </Box>
    );
}